<template>
  <main class="page-points page-notes-point p-0 d-flex flex-column">
    <ul class="nav nav-pills nav-fill">
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'projects.show' }">Dati</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'projects.edit' }">Modifica</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'projects.visea' }">Modulo ViSEA</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'projects.visea.graph' }">Modulo ViSEA (Grafo)</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link active" :to="{ name: 'projects.tutorial' }" aria-current="page">Tutorial</router-link>
      </li>
    </ul>
    <div class="flex-grow-1 container-fluid pt-3">
      <div class="nav nav-tabs nav-tabs-vertical" id="nav-vertical-tab" role="tablist" aria-orientation="vertical">
        <a v-for="(note, i) in notes" class="nav-link" :class="{ 'active': !newNote && selectedNoteId === note.id }" id="nav-vertical-tab1-tab" data-toggle="tab" @click.prevent="editNote(note.id)" role="tab" aria-controls="nav-vertical-tab1" aria-selected="true" :key="note.id">
          {{ i + 1 }}
        </a>
        <a class="nav-link" :class="{ 'active': newNote }" @click.prevent="addNote">Aggiungi</a>
      </div>
      <div class="tab-content" v-if="newNote">
        <TutorialNoteForm :feedback.sync="feedback" @submitForm="saveNewNote" />
      </div>
      <div class="tab-content" v-else-if="note">
        <div class="mt-3 text-left" v-if="isAdmin">
          <button type="button" @click.prevent="removeActiveNote" class="btn btn-outline-danger mb-4">Cancella contenuto</button>
        </div>
        <TutorialNoteForm :feedback.sync="feedback" edit :note="note" @submitForm="updateNote" />
      </div>
      <div class="tab-content" v-else>
        <template v-if="notes.length === 0">Aggiungi un nuovo contenuto</template>
        <template v-else>Seleziona un contenuto esistente o aggiungine uno nuovo</template>
      </div>
    </div>
  </main>
</template>

<script>

import rolesMixin from '@/mixins/roles';
import confirmWithModalMixin from '@/libs/Confirm/mixins/confirmWithModal';
import pageableMixin from '@/libs/Pagination/mixins/pageable';
import $api from '@/libs/OAuth2/services/api';

export default {
  mixins: [rolesMixin, confirmWithModalMixin, pageableMixin],
  components: {
    TutorialNoteForm: () => import('@/views/components/Form/TutorialNoteForm'),
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      feedback: null,
      newNote: null,
      selectedNoteId: null,
      notes: [],
    };
  },
  computed: {
    note () {
      return this.notes.find(n => n.id === this.selectedNoteId);
    },
  },
  methods: {
    fetchResults () {
      return $api.fetchProjectTutorial(this.project.id)
        .then(res => {
          this.notes = res?.data?.notes || [];
        })
        .catch(() => this.$log.error)
      ;
    },
    editNote (id) {
      this.selectedNoteId = id;
      this.feedback = null;
      this.newNote = null;
    },
    addNote () {
      this.newNote = {
        description: null,
        images: [],
      };
    },
    saveNewNote (noteForm) {
      const note = { ...noteForm };

      this.$api.createProjectTutorial(this.project.id, note)
        .then(() => {
          this.feedback = true;
          return this.fetchResults();
        })
        .then(() => {
          this.editNote(null);
        })
        .catch(err => {
          this.feedback = false;
          this.$log.error(err);
        })
      ;
    },
    updateNote (noteForm) {
      const note = { ...noteForm };

      this.$api.updateProjectTutorial(note.id, note)
        .then(() => {
          this.feedback = true;
          return this.fetchResults();
        })
        .then(this.abortEditNote)
        .catch(err => {
          this.feedback = false;
          this.$log.error(err);
        })
      ;
    },
    removeActiveNote () {
      this.confirm('Cancellare il contenuto e tutte le immagini collegate?', 'Sì', 'No')
        .then(confirmed => {
          if (!confirmed) {
            return;
          }

          if (!this.note) {
            return;
          }

          this.$api.removeProjectTutorial(this.note.id)
            .then(() => {
              return this.fetchResults();
            })
            .then(() => {
              this.editNote(null);
            })
            .catch(this.$log.errors)
          ;
        })
      ;
    },
    abortEditNote () {
      this.editNote(null);
    },
  },
  mounted () {
    this.fetchResults();
  },
};

</script>
